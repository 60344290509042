<template>
    <div>
    <div class="empresa-view">
      <div class="empresa-content">
        <h1 style="color:greenyellow;margin-top:60px;padding-left: 40px;width: max-content;" >Empresa</h1>
      </div>
    </div>
     <div class="empresa-paragraph-container">
        <p class="paragraph">
          Pantalla en construcción
        </p>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'EmpresaView'
}
</script>

  <style>
  .empresa-view {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP81YKoXxZ1dTHfS-vdjG7OFmnDybwwLJTYw&usqp=CAU");
    background-size: cover;
    background-position: center;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .empresa-content {
    flex-grow: 1;
  }
  .empresa-paragraph-container {
    background-color: rgba(102, 207, 45, 0.5);
    padding: 20px;
    border: 1px solid white;
    margin: 20px;
  }
  .paragraph {
    color: white;
    text-align: center;
    font-size: 16px;
  }
  </style>
