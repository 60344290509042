<template>
  <v-app style="background-color: black;">
    <v-app-bar app color="black" dark>
      <div class="d-flex align-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-img
              ref="dialogButton"
              alt="Murciélago Logo"
              class="shrink mr-2"
              contain
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwuS9P88FijGpvP9Q_HE9aa7g8D3tVVAE13w&usqp=CAU"
              transition="scale-transition"
              width="40"
              v-on="on"
              style="cursor: pointer"
            />
          </template>

          <v-list class="styled-menu" style="background-color: black;">
            <v-list-item v-for="(item, index) in menuItems" :key="index" @click="navigateTo(item)">
              <v-list-item-title class="styled-menu-item">{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-img
          alt="Murciélago Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="URL_DEL_NOMBRE_DE_MURCIÉLAGO"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view v-if="!loading" />
      <Loader v-else />
    </v-main>
    <v-footer app color="black" dark>
      <v-spacer></v-spacer>
      <v-btn
        color="transparent"
        dark
        fab
        small
        :href="getWhatsAppLink()"
        target="_blank"
      >
        <v-icon style="color: green;" left>mdi-whatsapp</v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import VueRouter from 'vue-router'
import Loader from '../src/components/PageLoader.vue'

import OrigenView from '../src/components/OrigenView.vue'
import EmpresaView from '../src/components/EmpresaView.vue'
import ProductoView from '../src/components/ProductoView.vue'
import ComunidadView from '../src/components/ComunidadView.vue'
import HelloWorld from '../src/components/HelloWorld.vue'

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/home', component: HelloWorld },
  { path: '/origen', component: OrigenView },
  { path: '/empresa', component: EmpresaView },
  { path: '/producto', component: ProductoView },
  { path: '/comunidad', component: ComunidadView }
]

const router = new VueRouter({
  routes
})

export default {
  name: 'App',

  components: {
    Loader
  },

  data () {
    return {
      loading: true,
      menuItems: ['Home', 'Origen', 'Empresa', 'Producto', 'Comunidad'],
      phoneNumber: '3543653127'
    }
  },

  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 4000)
  },

  methods: {
    closeMenu () {
      this.$refs.dialogButton.isActive = false
    },
    navigateTo (item) {
      const routeName = item.toLowerCase()
      const currentRouteName = this.$route.name
      if (currentRouteName !== routeName) {
        const isCurrentRoute = this.$route.path === `/${routeName}`
        if (!isCurrentRoute) {
          this.$router.push(routeName)
        }
      }
      this.$refs.dialogButton.isActive = false
    },
    getWhatsAppLink () {
      return `https://wa.me/${this.phoneNumber}`
    }
  },

  router
}
</script>

<style>
.styled-menu .styled-menu-item {
  color: yellow !important;
  text-align: center;
  font-weight: bold;
  background-color: black;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.whatsapp-icon {
  color: green;
}
</style>
