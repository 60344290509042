<template>
  <div class="loader">
    <v-img
      alt="Loading GIF"
      src='https://pa1.narvii.com/6597/465e7ff652957e7b7baa82460459d77ca7145b63_hq.gif'
      contain
      width="90%"
      class="rounded-loader"
    />
  </div>
</template>

<script>
export default {
  name: 'PageLoader.vue'
}
</script>

<style>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.rounded-loader {
  border-radius: 70%;
}
</style>
