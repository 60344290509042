<template>
  <v-container fluid style="padding-top: 0">
    <v-row justify="center">
      <v-col cols="12" class="text-center" style="padding-bottom: 10px;">
        <!-- <v-img
          class="hero-image1"
          :src="currentImage"
          aspect-ratio="1"
          contain
        >
          <v-row align="center" justify="center" class="fill-height">
            <v-col cols="12">

            </v-col>
          </v-row>
        </v-img> -->
        <v-img
          class="hero-image2"
          :src="bannerImage"
          aspect-ratio="1"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      bannerImage: 'https://i.ibb.co/5TXcLJM/IMG-20230626-WA0006.jpg',
      bannerSecond: 'https://i.ibb.co/Mpp3Vs0/IMG-20230516-WA0010.jpg',
      images: [
        'https://i.ibb.co/Mpp3Vs0/IMG-20230516-WA0010.jpg',
        'https://i.ibb.co/T1kmqJ9/IMG-20230516-WA0008.jpg',
        'https://i.ibb.co/9qQmZV2/IMG-20230517-WA0001.jpg'
      ],
      currentIndex: 0,
      buttonColor: 'transparent',
      buttonTextColor: 'white--text'
    }
  },
  mounted () {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length
      this.buttonColor = 'black'
      this.buttonTextColor = this.buttonColor === 'black' ? 'yellow--text' : ''
    }, 4000)
  },
  computed: {
    currentImage () {
      return this.images[this.currentIndex]
    }
  }
}
</script>

<style>
.hero-image1 {

  background-position: top;
}
.hero-image2 {
  min-height: 600px;
}
.transparent-button .v-btn__content {
  color: inherit;
}

</style>
