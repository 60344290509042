<template>
  <div class="comunidad-view">
    <h1 style="color: goldenrod">Sitios Amigos</h1>
    <div class="card" v-for="(sitio, index) in sitiosAmigos" :key="index" @click="navigateTo(sitio)">
      <img :src="sitio.imagen" alt="Sitio Amigo" class="card-image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComunidadView',
  data () {
    return {
      sitiosAmigos: [
        { imagen: 'https://i.ibb.co/DgJTfBf/Whats-App-Image-2023-12-12-at-17-06-27.jpg', url: 'https://wa.me/3516146488' },
        { imagen: 'https://i.ibb.co/VNj6fKM/Whats-App-Image-2023-12-13-at-21-29-13.jpg', url: 'https://wa.me/3513715505' }
      ]
    }
  },
  methods: {
    navigateTo (sitio) {
      window.open(sitio.url, '_blank')
    }
  }
}
</script>

<style>
.comunidad-view {
  display: flex;
  flex-direction: column; /* Añadido para establecer la dirección de la columna */
  align-items: center; /* Añadido para centrar los elementos horizontalmente */
}

.card {
  width: 160px;
  height: 160px;
  margin: 10px;
  border: 1px solid gray;
  cursor: pointer;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
