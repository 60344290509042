<template>
    <div>
    <div class="origen-view">
      <div class="content">
        <h1 style="color:greenyellow;margin-top:80px;padding-left: 40px;width: max-content;" >Origen del producto</h1>
      </div>
    </div>
     <div class="paragraph-container">
        <p class="paragraph">
          Pantalla en construcción
        </p>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'OrigenView'
}
</script>

  <style>
  .origen-view {
    background-image: url("https://imagenes.muyinteresante.es/files/article_social/uploads/2022/10/12/6346d72564014.jpeg");
    background-size: cover;
    background-position: center;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .content {
    flex-grow: 1;
  }
  .paragraph-container {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    border: 1px solid white;
    margin: 20px;
  }
  .paragraph {
    color: white;
    text-align: center;
    font-size: 16px;
  }
  </style>
