import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrigenView from '../../src/components/OrigenView.vue'
import ProductoView from '../../src/components/ProductoView.vue'
import EmpresaView from '../../src/components/EmpresaView.vue'
import ComunidadView from '../../src/components/ComunidadView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/origen',
    name: 'origen',
    component: OrigenView
  },
  {
    path: '/producto',
    name: 'producto',
    component: ProductoView
  },
  {
    path: '/comunidad',
    name: 'comunidad',
    component: ComunidadView
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: EmpresaView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
